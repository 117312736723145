import "../ArticleDetailsSlider/articleDetailsSlider.css"
import React, { Component } from "react";
import Slider from "react-slick";
import Img1 from "../../images/automation.png"

export default class ArticleDetailsSlider extends Component {
  render() {
    const DetailsData  = [
      {imgUrl:Img1,text:"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"},
      {imgUrl:Img1,text:"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"},
      {imgUrl:Img1,text:"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"},
      {imgUrl:Img1,text:"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"},
      {imgUrl:Img1,text:"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"},
      {imgUrl:Img1,text:"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"}
  ]
    const settings = {
      className: "center",
      infinite: true,
      centerPadding: "60px",
      slidesToShow: 4,
      swipeToSlide: true,
      afterChange: function(index) {
        console.log(
          `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
        );
      }
    };
    return (
      <section className="articleDetailsSlider">
        <div className="container">
          <div className="row">
          <div>
        <h2>RELATED STORIES</h2>
        <Slider {...settings}>
          {
            DetailsData.map((items,index)=>(
              <div key={index} className="DetailsSliderdata">
                <img src={items.imgUrl} alt=""/>
                <p>{items.text}</p>
              </div>
            ))
          }
         
        </Slider>
      </div>
          </div>
        </div>
      </section>
    );
  }
}
