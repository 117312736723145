import "./Industries.css";
import useFetch from "../FetchApiData/FetchApidata";
function Industries(props) {
  const { data} = useFetch(props);
  const [industrisInfo] = data;
  const industriesData  = industrisInfo?.data;
  return (
    <>
    {data && 
        <div className="industries">
        <h2>{industriesData?.name}</h2>
        <div className="row container industrycontainer">
          {industriesData?.gallery.map((items, index) => (
            <>
              <div className="col-lg-3 col-md-6 industriesimgcontainer" key={index}>
                <div className="overlay"></div>
                {items?.imageUrl && <img
                  className="industriesimg"
                  src={items?.imageUrl}
                  alt=""
                />}
                <div className="industriesheading">
                  <h4>{items?.name}</h4>
                  <i class="fa fa-angle-right" aria-hidden="true"></i>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    }
    </>
  );
}
export default Industries;
