import React, { useState, useRef } from "react";
// import { SlArrowRight,SlArrowLeft} from "react-icons/sl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import "./Module.css";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import useFetch from "../FetchApiData/FetchApidata";
library.add(faChevronLeft);

const Module = (props) => {
  const {data,playlistData} = useFetch(props);
  const [...moduledetails] = data;
  const modulesdetailsdata = moduledetails;

  const itemsData=[]
  const moduleListData=[]
  for(let i of modulesdetailsdata){
    moduleListData.push(i?.data?.name)
    if (i.data.images.length>0){
      for (let j of i.data.images[0].imageUrl){
        itemsData.push({title:i.data.name,
        description:i.data.subTitle,
        listItems:i.data.pageContent,videoUrl:"https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
        poster:j,videoType:"video/mp4",id:i.data.name})
      }
    }
  }


  const x = React.useRef(null);

  const [modalOpen, setModalOpen] = useState(false);
  const videoRef = useRef(null);
  const moduleList = moduleListData;
  const Data = itemsData;

  const settings = {
    className: "center",
    dots: true,
    centerMode: true ,
    infinite: true,
    centerPadding: "80px",
    slidesToShow: 1,
    arrows: false,
    speed: 1000,
    autoplay: false,
    autoplaySpeed: 5000,

    // cssEase: "linear",
    appendDots: (dots) => (
      <div style={{}}>
        <ul style={{ padding: "0px" }}> {dots} </ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          arrows: true,
          centerMode: false,
          centerPadding: '40px',
          slidesToShow: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          centerMode: false,
          centerPadding: '40px',
          slidesToShow: 1
        }
      }
    ],
    customPaging: (i) => (
      <div
        className="moduleList"
        style={{
          width: "220px",
          color: "#707070",
          font: "normal normal 600 22px/26px Inter",
          font: "var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) 22px/26px var(--unnamed-font-family-inter)",
          marginBottom: "50px",
          letterSpacing: "var(--unnamed-character-spacing-0)",
          letterSpacing: "0px",

        }}
      >
        <p
          style={{
            font: "normal normal 600 22px/26px Inter",
            font: "var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) 22px/26px var(--unnamed-font-family-inter)",
          }} >{moduleList[i]}</p>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const [videoUrl, setVideoUrl] = useState("");
  const [videoId, setVideoId] = useState("liveTv");
  const handleModel = (id, url) => {
    setVideoId(id);
    setVideoUrl(url);
  };


  return (
    <>
      <div className="">
        <div id="module" className="container-fluid">
          <div className="tittle">
            <h2>{playlistData?.headline}</h2>
            <h6>
            {playlistData?.alternateHeadline}
            </h6>
          </div>

          <Slider ref={x} {...settings} className="slider">
            {Data.map((slide, index) => {
              const {
                title,
                description,
                listItems,
                videoUrl,
                videoType,
                poster,
                className,
                id,
              } = slide;
              return (
                <>
                  <div className="module-containt key-slider" key={index}>
                    <div className="row slider">
                      <div className="module-details  col-lg-6 col-md-12 ps-md-5">
                        <h3>{title}</h3>
                        <p>{description}</p>
                        <ul className="ps-4">
                          <div dangerouslySetInnerHTML={{ __html:listItems}} className="listItemsli" />
                        </ul>
                      </div>
                      <div
                        className="video-container position-relative col-lg-6 col-md-12"
                        //below code for video
                        // onClick={() => handleModel(id, videoUrl)}
                        // data-bs-toggle="modal"
                        // data-bs-target={`#${videoId}`}
                      >
                        <video
                          src={videoUrl}
                          poster={poster}
                          type={videoType}
                          muted
                          className={className}
                          loop
                        ></video>

                      </div>
                    </div>
                  </div>

                </>
              );
            })}
          </Slider>

          <button className="leftBtn sbtn" onClick={() => x.current.slickPrev()}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <button className="rightBtn sbtn" onClick={() => x.current.slickNext()}>
            <FontAwesomeIcon icon={faChevronRight} />
          </button>


          <div className="responsiveSlider">
            {Data.map((slide, index) => {
              const {
                title,
                description,
                listItems,
                videoUrl,
                videoType,
                poster,
                className,
                id,
              } = slide;
              return (
                <>
                  <div className="module-containt key-slider" key={index}>

                    <div className="module-details  col-lg-6 col-md-12 ps-md-5">
                      <h3>{title}</h3>
                      <p>{description}</p>

                      <div
                        className="video-container position-relative col-lg-6 col-md-12"
                        onClick={() => handleModel(id, videoUrl)}
                        data-bs-toggle="modal"
                        data-bs-target={`#${videoId}`}
                      >
                        <video
                          src={videoUrl}
                          poster={poster}
                          type={videoType}
                          muted
                          className={className}
                          loop
                        ></video>

                      </div>
                      <ul className="ps-4">
                          <div dangerouslySetInnerHTML={{ __html:listItems}} />
                        </ul>
                    </div>


                  </div>

                </>
              );
            })}
          </div>


          <div
            className="modal fade "
            id={videoId}
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <button

                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
                <div className="modal-body">
                  <video className="popVideo" src={videoUrl} autoPlay controls ></video>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Module;
