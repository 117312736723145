import React from "react";
import "./Capabilities.css"
import useFetch from "../FetchApiData/FetchApidata";

const Capabilities = (props) => {
  const capabilitiesData = []
  const {data} = useFetch(props);
  const [capabilitiesDetails] = data;
  const capabilitiesDataTitles  = capabilitiesDetails?.data;
  for(let i in capabilitiesDataTitles?.gallery){
      capabilitiesData.push({id:i,text:capabilitiesDataTitles?.gallery[i].name,src:capabilitiesDataTitles?.gallery[i]?.imageUrl})
  }
  
  const handleClass = (index) => {
    if (index === 2 || index === 3 || index === 8) {

      return "container-content container-content-black-background"
    } else {

      return "container-content"
    }
  }
  const handleClassCenter = (index) => {
    if (index === 1 || index === 4 || index === 7) {

      return "container-content-black-background-center"
    } else {

      return "container-content-noncenter"
    }
  }

  const handleClassR = (index) => {
    if (index === 2 || index === 3 || index === 8) {

      return "r-black-background"
    } else {

      return "r-content"
    }
  }
 

  const stopAnimation = (e, i) => {
    const ele = document.querySelectorAll('.container-content');
    ele.forEach((element, index) => {
      if (i === index) {
        element.style.animation = "unset"
        element.style.transform = "scale(1.1)"
      } else {
        element.classList.add('pause-animation');
      }
    })
  }
  const activateAnimation = (e, i) => {
    const ele = document.querySelectorAll('.container-content');
    ele.forEach((element) => {
      element.classList.remove('pause-animation');
      element.style.animation = "zoom-in-zoom-out 6s ease infinite"
    })
  }
  return (
   
    <>
    {
      data && 
      <>
      <div className="mainCapabilties">
        <div className="container-fluid">
          <div id="capabilities" className="container d-block ">
            <h1>{capabilitiesDataTitles?.name}</h1>
            <p className="conatiner-p">{capabilitiesDataTitles?.translatedTitle}</p>
            <div className="main">
              {capabilitiesData.map((val, index) => {
                return <div className={handleClass(index)} id={handleClassCenter(index)} key={index}
                  onMouseOver={(e) => stopAnimation(e, index)}
                  onMouseOut={(e) => activateAnimation(e, index)}
                >
                  {val.src && <img src={val.src} alt="" className="imgsrc" />}
                  <p className="imgtext">{val.text}</p> 
                </div>
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="responsiveFeatures">
        <div className="container-fluid">
          <div id="capabilities" className="container d-block ">
            <h1>{capabilitiesDataTitles?.name}</h1>
            <p className="conatiner-p">{capabilitiesDataTitles?.translatedTitle}</p>
            <div className="main">
              {capabilitiesData.map((val, index) => {
                return <div className={handleClassR(index)} key={index}>
                  {val.src && <img src={val.src} alt="" className="imgsrc" />}
                  <p className="imgtext">{val.text}</p>
                </div>
              })}
            </div>
          </div>
        </div>
      </div>
      </>
    }
      
    </>
    
  );
}
export default Capabilities;





