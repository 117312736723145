import "./Banner.css";
import useFetch from "../FetchApiData/FetchApidata";
const Banner = (props) => {
  const { data } = useFetch(props);
  const [bannerInfo] = data || [];
  const BannerData = bannerInfo?.data || {};
  return (
    <>
      {bannerInfo && (
        <div className="container-fluid banner position-relative">
          <div className="container banner_section layout_padding">
            <div className="row banner-container ">
              <div className=" col-md-5 banner_main">
                <div className="banner_title_main">
                  <div className="banner_title">
                    <h4>{BannerData.name}</h4>
                  </div>
                  <div className="banner_text">
                    <p>{BannerData.subTitle}</p>
                  </div>
                  <button type="submit" className="btn-lg">
                    <a
                      className="btn-lg"
                      aria-current="page"
                      href="#talkToExpert"
                    >
                      Schedule a Demo
                    </a>
                  </button>
                </div>
              </div>
              {BannerData?.images[0] && (
                <div className="col-md-7 banner-right-images  ">
                  <div className=" banner_images_main row">
                    <div className=" banner_images_one col-md-4">
                      {BannerData?.images[0]?.imageUrl[0] && (
                        <img
                          className="image1"
                          src={BannerData?.images[0]?.imageUrl[0]}
                          alt=""
                        />
                      )}
                      {BannerData?.images[0]?.imageUrl[4] && (
                        <img
                          className="image5"
                          src={BannerData?.images[0]?.imageUrl[4]}
                          alt=""
                        />
                      )}
                    </div>
                    {BannerData?.images[0] && (
                    <div className="p-0 banner_images_two col-md-7">
                      <div className="row">
                        {BannerData?.images[0]?.imageUrl[1] && (
                          <img
                            className="image2 col-lg-6 p-0"
                            src={BannerData?.images[0]?.imageUrl[1]}
                            alt=""
                          />
                        )}
                        {BannerData?.images[0]?.imageUrl[3] && (
                          <img
                            className="image4 col-lg-6 p-0"
                            src={BannerData?.images[0]?.imageUrl[3]}
                            alt=""
                          />
                        )}
                      </div>
                      <div className="img3">
                        {BannerData?.images[0]?.imageUrl[2] && (
                          <img
                            className="image3"
                            src={BannerData?.images[0]?.imageUrl[2]}
                            alt=""
                          />
                        )}
                      </div>
                    </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="ResponsiveImage">
            {BannerData?.images[1]?.imageUrl[0] && (
              <img  
                className="rImage"
                alt="XMS Screenshots"
                src={BannerData?.images[1]?.imageUrl[0]}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default Banner;
