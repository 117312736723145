import React from "react";
import "./VideoBanner.css";
import Video from "./video/background.mp4";
import Video1 from "./video/mobileview.mp4";
import useFetch from "../FetchApiData/FetchApidata";
const VideoBanner = (props) => {
  const { data } = useFetch(props);
  const [videoBannerDetailsWeb, videoBannerDetailsmob] = data;
  const videoBannerDataWeb = videoBannerDetailsWeb?.data;
  const videoBannerDatamob = videoBannerDetailsmob?.data;
  return (
    <>
      {data && (
        <>
          <div className="background-video  d-md-block">
            <video src={Video} muted autoPlay loop></video>
            <div className="video-text container">
              <h2>{videoBannerDatamob?.name} </h2>
              <p>{videoBannerDatamob?.subTitle}</p>
              <button type="button" className="btn btn-lg mx-auto">
                <a aria-current="page" href="#talkToExpert">
                  Schedule a Demo   </a>
              </button>
              <div id="mod"></div>
            </div>
          </div>
          <div className="background-video1 ">
            <video src={Video1} muted autoPlay loop></video>
            <div className="video-text container">
              <h2>{videoBannerDataWeb?.name}</h2>
              <p>{videoBannerDataWeb?.subTitle}</p>
              <button type="button" className="btn btn-lg mx-auto">
                <a aria-current="page" href="#talkToExpert">
                Schedule a Demo  
                </a>
              </button>
            </div>
            <div id="mod"></div>
          </div>
        </>
      )}
    </>
  );
};
export default VideoBanner;
