import React from "react";
import { useParams } from "react-router";
import "./articleDetails.css";
import ArticleDetailsSlider from "./ArticleDetailsSlider/ArticleDetailsSlider";
import Img1 from "../images/Capabilitiesimg9.png"


const ArticleDetails = () => {
  const { id } = useParams();
  return (
    <>
      <section className="ArticlesDetails">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 articleDetailsContainer">
              <div>
                <h1>
                  Businesses in Japan receive abusive calls from China after
                  Fukushima water release
                </h1>
                <p>
                  {" "}
                  <span>Justin McCurry</span> in Tokyo and agencies
                </p>
                <h6>Mon 28 Aug 2023 02.44 BST</h6>
                <h3>
                  Companies across Japan reported so many calls from Chinese
                  speakers that they had difficulty operating
                </h3>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 articleDetailsContainerImg">
              <div className="SiderImg">
                <img
                  src={Img1}
                  alt="edef"
                />
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </section>
      <section className="articleDetailsDescription">
        <div className="container">
          <div className="row">
            <div className="articleDetailsContentDescription">
                <ul>
                    <li>
                    Japan has urged its citizens in China to avoid using their
                    native tongue in loud voices, while its companies have
                    received a wave of abusive calls amid a rise in anti-Japanese
                    sentiment sparked by the controversial discharge of wastewater
                    from the Fukushima Daiichi nuclear power plant.
                    </li>
                    <li> 
                        While Japan
                    insists the treated water is safe – a view backed by the UN’s
                    nuclear watchdog – China has staunchly opposed the discharge,
                    which began last week, and banned all Japanese seafood
                    imports. Beijing has called the decision to release the water
                    “selfish” and harmful to the environment and human health.
                    </li>
                    <li>
                        The
                    Japanese government on Sunday published new data showing
                    radioactivity levels in waters off Fukushima continued to be
                    well within safe limits. Scientists have pointed out that
                    China’s own nuclear power plants release wastewater with
                    higher levels of tritium than that found in Fukushima’s
                    discharge, and that the levels are all within boundaries not
                    considered to be harmful to human health.
                    </li> 
                    <li>
                        Calls from China
                    began flooding Japanese businesses from Thursday, when the
                    plant’s operator, Tokyo Electric Power (Tepco), started
                    releasing water used to cool the stricken nuclear reactors at
                    Fukushima Daiichi.
                    </li>
                    <li>
                        Japanese businesses and groups, ranging
                    from a concert hall in Tokyo to an aquarium in the northern
                    prefecture of Iwate, reported that they had started receiving
                    so many calls from Chinese speakers that they had difficulty
                    conducting normal operations.
                    </li>
                </ul>
            </div>
          </div>
        </div>
      </section>
      <ArticleDetailsSlider />
    </>
  );
};

export default ArticleDetails;
