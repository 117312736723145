import React, { useEffect } from "react";
import { useState } from "react";
import "./FaqSection.css";
import useFetch from "../FetchApiData/FetchApidata";
function FaqSection(props) {
  const { data, playlistData } = useFetch(props);
  const [accordionData, setAccordionData] = useState([]);
  const faqDataSagrigation = (data) => {
    const dataFaq = [];
    let faqCategoryData = {};

    for (let i of data) {
      const category = i.data.category;
      const existingCategory = Object.keys(faqCategoryData).includes(category);
      if (!existingCategory) {
        const categoryObject = {
          [category]: [
            {
              title: i?.data?.name,
              accordionContent: i?.data?.answer,
            },
          ],
        };
        faqCategoryData = { ...faqCategoryData, ...categoryObject };
      } else {
        const currentCategoryData = { ...faqCategoryData };
        const itemData = {
          title: i?.data?.name,
          accordionContent: i?.data?.answer,
        };
        currentCategoryData[category] = [
          ...currentCategoryData[category],
          { ...itemData },
        ];
        faqCategoryData = currentCategoryData;
      }
    }
    faqCategoryData &&
      Object.keys(faqCategoryData).length > 0 &&
      Object.keys(faqCategoryData).forEach((item, index) => {
        const accordionObject = {
          id: index,
          tab: item,
          child: faqCategoryData[item],
        };
        dataFaq.push(accordionObject);
      });
    setAccordionData(dataFaq);
  };
  useEffect(() => {
    faqDataSagrigation(data);
  }, [data]);

  const [number, setNumber] = useState(1);
  return (
    <>
      {data && (
        <div className="backgroundleft-image1">
          <div className="container faq faq-container ">
            <h1>{playlistData?.headline}</h1>
            <div className="FAQsButtons">
              {accordionData &&
                accordionData.map((acc) => (
                  <button
                    className={
                      number == acc.id ? "services active" : "services"
                    }
                    id={acc.id}
                    onClick={() => setNumber(acc.id)}
                  >
                    {acc?.tab[0].toUpperCase() + acc?.tab.slice(1)}
                  </button>
                ))}
              <hr></hr>
            </div>

            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              {accordionData &&
                accordionData.map((acc) => {
                  const { id, tab, child } = acc;

                  if (id == number) {
                    return (
                      <>
                        {child.map((children, index) => {
                          let header_id = "flush-heading" + index;
                          let collapse_id = "flush-collapse" + index;
                          let header_id_hash = "#flush-collapse" + index;
                          const count = index + 1;
                          return (
                            <>
                              <div className="accordion-item" key={child.title}>
                                <h2 className="accordion-header" id={header_id}>
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={header_id_hash}
                                    aria-expanded="false"
                                    aria-controls="flush-collapseOne"
                                  >
                                    {`${count}.  ${children.title}`}
                                  </button>
                                </h2>
                                <div
                                  id={collapse_id}
                                  className="accordion-collapse collapse"
                                  aria-labelledby="flush-headingOne"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    {" "}
                                    {children.accordionContent}
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </>
                    );
                  }
                })}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default FaqSection;
