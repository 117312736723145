import React from "react";
import "./footer.css"
import XmsFootLogo from "../../images/logo.png"
import linkedinimg from "../../images/linkedin.png"
import youtubeimg from "../../images/youtube.png"
import twitterimg from "../../images/twitter.png"
import instagramimg from "../../images/instagram.png"
const Footer = () => {
  return (
    <>
      <footer>
        <div className="container footer-container row">

          <div className="col-md-4">
            <img src={XmsFootLogo} alt="" className="img" />
            <h2>XMS</h2>
            <p>An Extensible Management System for Publishing and Managing
              Content on Multiple Platforms, Simplified!</p>
          </div>
          <div className="col-md-4 col-lg-4">
            <ul className="p-0">
              <li> <a href="#business"> Business Benefits</a></li>
              <li> <a href="#cap"> Features</a></li>
              <li> <a href="#module"> Modules</a></li>
              <li> <a href="#talkToExpert"> Contact Us</a></li>
            </ul>

          </div>
          <div className="col-md-4 col-lg-4">
            {/* <ul className="p-0">
          <li> <a href=""><strong>Resources :</strong></a></li>
          <li> <a href=""> Product Guide</a></li>
          <li> <a href=""> Blog Posts</a></li>
        </ul> */}
            {/* <form className="d-xl-flex d-block">
          <input className="form-control me-2" type="email" placeholder="Email" aria-label="email"/>
          <button className="btn btn-outline-success download" type="button">Download</button>
        </form> */}
            <h6>Social</h6>
            <div className="d-flex">
              <a href="https://in.linkedin.com/company/engro"><img src={linkedinimg} alt="" /></a>
              <a href="https://www.youtube.com/@engro6118"><img src={youtubeimg} alt="" /></a>
              <a href="https://twitter.com/EngroTech"><img src={twitterimg} alt="" /></a>
              <a href="https://www.instagram.com/engrotechnologies/"><img src={instagramimg} alt="" /></a>

            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
export default Footer;