import React from "react";
import "./MainBody.css";
import Banner from "../banner/Banner";
import Unique from "../unique/Unique";
import BusinessBenefits from "../businessbenefits/BusinessBenefits";
import TrustedBy from "../trustedby/TrustedBy";
import Capabilities from "../capabilities/Capabilities";
import VideoBanner from "../videoBanner/VideoBanner";
import Module from "../module/Module";
import Expert from "../talktoanexpert/Expert";
import Industries from "../industries/Industries";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/footer";
import FaqSection from "../faq/FaqSection";
// const components = {Navbar, Banner, BusinessBenefits, TrustedBy, Capabilities, VideoBanner, Module, Expert, Industries, Unique, Footer, FaqSection}
const Components = {
  slider: Module,
  accordion: FaqSection,
  trustedBy: TrustedBy,
  businessBenefits: BusinessBenefits,
  navbar:Navbar,
  banner:Banner,
  capabilities:Capabilities,
  videoBanner:VideoBanner,
  expert:Expert,
  industries:Industries,
  unique:Unique,
  footer:Footer,
}


const MainBody = (props) => {
  return (
    <>
    {props?.data && 
      props?.data?.map(items=>{
      let PlaylistType = Components[items?.type];
      let PlaylistData = {...items, id:items['key']}
      return  Components[items.type]=== Banner ? 
      <div className="container-fluid main-body ">
        <div className="banner-unique">
       {Components[items.type] && <PlaylistType {...PlaylistData}/>} 
        </div>
      </div>
      :Components[items.type] && <PlaylistType {...PlaylistData}/>} )}
      
    </>     
  );
};
export default MainBody;
