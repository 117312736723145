import React from "react";
import "./TrustedBy.css";
import useFetch from "../FetchApiData/FetchApidata";
const TrustedBy = (props) => {
  const { data } = useFetch(props);
  const [trustedByDetails] = data;
  const trustedByData = trustedByDetails?.data;
  return (
    <>
      {trustedByDetails && (
        <div className="trustedby">
          <div className="container d-block">
            <h1>{trustedByData?.name}</h1>
            <div className="imagecontent row ">
              <div className=" imagecontainer1 row ">
                <div className="col-lg-1"></div>
                {trustedByData?.images[0]?.imageUrl && trustedByData?.images[0]?.imageUrl.map((image) => (
                  <>
                    { image && <img className="col-lg-3 " src={image} alt="" />}
                  </>
                ))}
                <div className="col-lg-1"></div>
              </div>
              <div className="imagecontainer2 row">
                <div className="col-lg-2"></div>
                {trustedByData?.images[1]?.imageUrl && trustedByData?.images[1]?.imageUrl.map((image) => (
                  <>
                    {image && <img className="col-lg-3 " src={image} alt="" />}
                  </>
                ))}
                <div className="col-lg-2"></div>
              </div>
            </div>
            <div id="cap"></div>
            <button type="button" className="btn btn-secondary btn-lg">
              <a aria-current="page" href="#talkToExpert">
                Schedule a Demo
              </a>
            </button>
          </div>
        </div>
      )}
    </>
  );
};
export default TrustedBy;
