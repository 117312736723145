import React from "react";
import "./BusinessBenefits.css";
import useFetch from "../FetchApiData/FetchApidata";
const BusinessBenefits = (props) => {
  const { data } = useFetch(props);
  const [bannerInfo] = data;
  const BannerData = bannerInfo?.data;
  return (
    <>
      {data && (
        <>
          <div id="buizz"></div>
          <div className = "backgroundleft-image">
            <div id="business" className = "container">
              <h2>{BannerData?.name}</h2>
              <p>{BannerData?.translatedTitle}</p>
              <div className="row justify-content-evenly">
                {BannerData?.gallery.map((items, index) => (
                  <>
                    <div className="card col-md-4 cardimg-hover" key={index}>
                      {items?.imageUrl && <img src={items?.imageUrl} alt="..." />}
                      <div className="card-body">
                        <h5 className="card-title">{items?.name}</h5>
                        <p className="card-text">{items?.description}</p>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default BusinessBenefits;
