import { Outlet } from "react-router-dom";
import XmsSmallLogo from "../../images/logo.png";
import "./Navbar.css";

const Navbar = () => {
  return (
    <>
      <div id="ban"></div>
      <div className="container-fluid   navitem">
        <nav className="navbar navbar-expand-lg navbar-dark  shadow-5-strong navbar-content ">
          <div className="container">
            <a className="navbar-brand " href="#ban">
              <img src={XmsSmallLogo} alt="" className="nav-img" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse navbar-collapse justify-content-end "
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav  mb-2 mb-lg-0">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="nav-brand-context"
                    aria-current="page"
                    href="#cap"
                  >
                    Features
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="nav-brand-context"
                    href="#mod"
                  >
                    Modules
                  </a>
                </li>
                {/* <li className="nav-item dropdown chooseindustry">
            <a className="nav-link dropdown-toggle " href="#"  id ='nav-dropdown' role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Choose Industry
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a className="dropdown-item" href="#">Media & Publishing</a></li>
              <li><a className="dropdown-item" href="#">Advertising Companies</a></li>
              <li><a className="dropdown-item" href="#">Educational Institutionse</a></li>
              <li><a className="dropdown-item" href="#">Industry 4.0</a></li>
            </ul>
          </li> */}
                <button
                  id="submit-btn"
                  className="login  schedule_btn d-lg-block  d-none "
                  type="submit"
                >
                  {" "}
                  <a href="#talkToExpert">Schedule a Demo</a>
                </button>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <Outlet />
    </>
  );
};

export default Navbar;
