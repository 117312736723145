import React from "react";
import "./Unique.css"
import useFetch from "../FetchApiData/FetchApidata";
const Unique =(props)=>{
  const {data} = useFetch(props);
  const [uniquedetails] = data || [];
  const uniqueData  = uniquedetails?.data || {};
    return(
        <>
        {
          uniquedetails && 
          <div className="backgroundright-image">
          <div id="unique" className="container ">
            <h2>{uniqueData?.name}</h2>
            <div className="row align-items-center uniquecontaint">
              {uniqueData?.images.length>0 && <img className="col-lg-5 col-md-12" src={uniqueData?.images[0]?.imageUrl[0]} alt=""/>}

              <p className="col-lg-7 col-md-12 parapadding"><div dangerouslySetInnerHTML={{ __html:uniqueData?.pageContent }}></div></p>
            </div>
          </div>
        </div>
        }
        </>
    );
}
export default Unique;